import gql from "graphql-tag";

export const GET_MANAGEMENT_PACKETCAPTURES = gql`
  query GET_MANAGEMENT_PACKETCAPTURES(
    $search: ShortString,
    $page: Int,
    $itemsPerPage: Int,
    $organizationSlug: ShortString,
    $requestedBy: String,
    $networkId: UUID, 
    $deviceId: String, 
    $hostId: UUID,
    $projectId: UUID,
    $orderBy: PacketCaptureOrderBy,
    $direction: Direction,
    $fromDate: DateTime,
    $toDate: DateTime,
    $status: [TaskStatusEnum!]

		$askForPagination: Boolean!,
  ){    
  	management{    
  		packetCaptures(
	    	page: $page,
	    	itemsPerPage: $itemsPerPage,
	  		organizationSlug: $organizationSlug, 
	  		requestedBy: $requestedBy, 
	  		networkId: $networkId, 
	  		deviceId: $deviceId, 
	  		hostId: $hostId, 
	  		projectId: $projectId, 
        orderBy: $orderBy,
        direction: $direction,
        search: $search,
        fromDate: $fromDate,
				toDate: $toDate,
				status: $status,
  		) {
    		pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
  			results {
	        id
	        scheduledTime
	        task{
	        	id
	        	endTime
	        	errorMessage
	        	status
		        startTime
	        	progress
	        	network{
	        		id
	        		location
	        	}
	        }	        
	        createdBy {
		        firstname
		        lastname
	        }
	        
	        ports{
	        	id
	        	label
	        	usage
	        	accessVlan{
		        	id
							vid
							name
							type
							accessPolicy
							description
	        	}
	        }
	        host{
	        	id
	        	macAddr
	        	displayName
	        }
					originalVlanName
	        vlan{
	        	id
						vid
						name
						type
						accessPolicy
						description
	        	management
	        }
        	network{
        		id
        		location
        	}
      		organization{
      			id
      			slug
      			name
      		}
	        device{
	        	id
	        	deviceId
						isAlive
	        	location
	          actions {
	            action
	            enabled
	            disabledReason
	          }
	        }	        
		      maxDuration
		      maxPackets
		      bpfFilter
		      packetCount
		      fileName
					fileSize					
	      }
  		}
  	}
  }
`;

export const GET_MANAGEMENT_PACKETCAPTURE = gql`
  query GET_MANAGEMENT_PACKETCAPTURE(
  	$packetCaptureId: UUID!,
  ){
  	management {
	    packetCapture(packetCaptureId: $packetCaptureId) {         
				id
        scheduledTime        
        task{
        	id
        	endTime
        	errorMessage
        	status
        	progress
        }	 
        createdBy {
	        firstname
	        lastname
        }        
        ports{
        	id
        	label
        	usage
        	accessVlan{
        		name
        	}
        }
        host{
        	id
        	macAddr
        	displayName
        }
        vlan{
        	id
					vid
					name
					type
					accessPolicy
					description
        	management
        }
      	network{
      		id
      		location
      		organization{
      			id
      			slug
      			name
      		}
      	}
        device{
        	id
        	deviceId
					isAlive
        	location
        }	        
	      maxDuration
	      maxPackets
	      bpfFilter
	      scheduledTime
	      packetCount
	      fileName
				fileSize
	    }
	  }
  }
`;